import React, { Component, Fragment } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Paper from '@material-ui/core/Paper';
import Drawer from './Common/Drawer'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AppBar from './Common/MainAppBar';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router-dom'
import Chip from '@material-ui/core/Chip';
import SearchSelect from '../Components/TagInput';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import { UpdatePage, GetPage } from '../Actions/LocationPages';
import { MyActivities, DeleteActivity, TrashTour } from '../Actions/ActivityActions';
import { GetSuppliersStausFilter } from '../Actions/SupplierActions';
import { fetchLocations } from '../Actions/LocationAction';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Tagselect from './Common/TasgSelectLoc'
import { fetchTags } from '../Actions/SettingsAction';

const drawerWidth = 240;


const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});

const getStatus = (status) => {
  switch (status) {
    case 0: { return "Draft" } //gray
    case 1: { return "Approved" } //green
    case 3: { return "In review" } //yellow
    case 4: { return "Banned" }// red
    case 2: { return "Rejected" }// black
    case 5: { return "Trashed" }// black
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } } //green #4CAF50
    case 3: { return { backgroundColor: '#FFEB3B', color: '#000' } } //yellow #FFEB3B
    case 4: { return { backgroundColor: '#f44336', color: '#FFF' } } //red #f44336
    case 2: { return { backgroundColor: '#1a1818', color: '#FFF' } } //black #f44336
    case 5: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
  }
}

const StatusChip = (status) => {
  return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
}


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    activePage: state.LocationPages.activePage,
    ActivitiesList: state.Activities.list,
    suppliers: state.Suppliers.list,
    locations: state.Locations.locations,
    tags: state.Tags.tags,
  }
}


const mapDispatchToProps = dispatch => ({

  UpdatePage: (data) => dispatch(UpdatePage(data)),
  MyActivities: (ttype, tsts, keyw, supplier, page, perPage) => dispatch(MyActivities(ttype, tsts, keyw, supplier, page, perPage)),
  DeleteActivity: (activityID, ttype, tsts, keyw) => dispatch(DeleteActivity(activityID, ttype, tsts, keyw)),
  TrashTour: (data) => dispatch(TrashTour(data)),
  GetSuppliers: (gStatus) => dispatch(GetSuppliersStausFilter(gStatus)),
  fetchLocations: () => dispatch(fetchLocations()),
  GetPage: (pid) => dispatch(GetPage(pid)),
  fetchTags: () => dispatch(fetchTags())
});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

class LocationPageCreate extends Component {

  constructor(props) {
    super(props);

    const { pid } = this.props.match.params;

    this.state = {
      id: 0,
      location: "",
      title: "",
      description: "",
      seo_title: "",
      seo_description: "",
      seo_url: "",
      full_seo_url: "",
      image_alt: "",
      activities: [],
      image_name: null,
      uploadImage: null,
      keyword: "",
      tourtype: "0",
      tourstatus: "1",
      supplier: "",
      page: 0,
      per_page: 10,
      tags: [],
    };

    this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword, this.state.supplier, this.state.page, this.state.per_page);
    this.props.GetSuppliers('1,3');
    this.props.fetchLocations();
    this.props.fetchTags();

    if (pid !== undefined && pid !== null) {
      this.props.GetPage(pid);
    }

  }

  getStatus = (status) => {
    switch (status) {
      case 0: { return "Draft" } //gray
      case 1: { return "Approved" } //green
      case 3: { return "In review" } //yellow
      case 4: { return "Banned" }// red
      case 2: { return "Rejected" }// black



    }
  }

  getTripType(type) {

    switch (type) {
      case 1: { return "Round tour" }
      case 2: { return "Day Tour" }
      case 3: { return "Activity" }
    }
  }

  getDuration(measure) {
    switch (measure) {
      case 1: { return "Days" }
      case 2: { return "Hours" }
      case 3: { return "Minutes" }
    }
  }

  selectkeyword = (e) => {
    this.setState({
      keyword: e.target.value
    }, () => {
      setTimeout(
        function () {
          this.searchTour();
        }
          .bind(this),
        1000
      );
    })
  }
  selectSupplier = (value) => {
    if (value === null) {
      this.setState({
        supplier: null
      }, () => this.searchTour());
    } else {
      this.setState({
        supplier: value.id
      }, () => this.searchTour());
    }

  }

  selecttourType = (e) => {
    this.setState({
      tourtype: e.target.value
    }, () => this.searchTour())
  }
  selecttourStatus = (e) => {
    this.setState({
      tourstatus: e.target.value
    }, () => this.searchTour())
  }


  searchTour = () => {

    this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword, this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier, this.state.page, this.state.per_page);
  }


  handleDeleteActivity = (aid) => {
    this.props.TrashTour({ id: aid, status: 5, remark: '', title: 'Move tour to trash', ttype: this.state.tourtype, tsts: this.state.tourstatus, keyw: this.state.keyword, supplier: this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier, page: this.state.page, perPage: this.state.per_page });
    //this.props.DeleteActivity(aid,this.state.tourtype, this.state.tourstatus, this.state.keyword);
  }

  handleChangeRowsPerPage = event => {
    this.setState({
      page: 0,
      per_page: +event.target.value
    }, () => this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword, this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier, this.state.page, this.state.per_page));
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage + 1,
    }, () => this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword, this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier, this.state.page, this.state.per_page));
  };

  componentWillReceiveProps = (nextProps) => {

    this.setState({
      id: nextProps.activePage.id,
      location: nextProps.activePage.location,
      title: nextProps.activePage.title,
      description: nextProps.activePage.description,
      seo_title: nextProps.activePage.seo_title,
      seo_description: nextProps.activePage.seo_description,
      seo_url: nextProps.activePage.seo_url,
      full_seo_url: nextProps.activePage.full_seo_url,
      image_alt: nextProps.activePage.image_alt,
      activities: nextProps.activePage.activities,
      image_name: nextProps.activePage.image_name,
      tags: nextProps.activePage.tags,
    });
  }

  componentDidMount() {
    document.body.style.background = "#eee";
  }

  addTourToPage = (data) => {
    this.setState({
      ...this.state,
      activities: [
        ...this.state.activities,
        data
      ]
    })
  }

  TagChange = (newValue) => {

    this.setState({
      tags: newValue,
      activities: newValue === null ? [] : this.state.activities
    })
  }

  removeFromPage = (index) => {
    this.setState({
      ...this.state,
      activities: [
        ...this.state.activities.slice(0, index),
        ...this.state.activities.slice(index + 1),
      ]
    })
  }

  handleChange = name => event => {

    console.log(name);
    this.setState({
      [name]: event.target.value,
    }, () => this.searchTour());

  };



  handleChangeP = (event, name) => {
    this.setState({
      [name]: event.target.value,
    });

  };

  uploadImage = (e) => {

    if (e.target.files[0] != null) {

      this.setState({
        uploadImage: e.target.files[0],
      });
    }

  }


  onSave = () => {
    this.props.UpdatePage(this.state);
    return <Redirect to="/LocationPagesCreate" />;
  }

  render() {

    const { classes } = this.props;




    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>

        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: 'white', width: 500, fontWeight: 600, fontSize: 15 }}>Page settings</TableCell>
              <TableCell style={{ backgroundColor: 'white', width: 0, fontWeight: 600, fontSize: 15 }}>
                <Button style={{ width: 150, marginLeft: 350, background: "#f7a414" }} variant="contained" color="primary" size="small" component={Link} to="/LocationPages" className={classes.button}>Back</Button>
              </TableCell>



            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>

              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 0 }}>


                  <table width="100%" >
                    <tr>
                      <td width="50%" >
                        <div style={{ marginTop: 25 }}>
                          <FormControl variant="outlined" >
                            <InputLabel
                              ref={ref => {
                                this.InputLabelRef = ref;
                              }}
                              htmlFor="outlined-age-native-simple"
                            >
                              Location
                            </InputLabel>
                            <Select
                              native
                              value={this.state.location}
                              onChange={(e) => { this.handleChangeP(e, 'location') }}
                              input={
                                <OutlinedInput
                                  name="Duration"
                                  labelWidth={70}
                                  id="outlined-age-native-simple"
                                />
                              }
                            >
                              <option value="" />
                              {this.props.locations !== null ? this.props.locations.map((loc, index) => {
                                return <option value={loc.value}>{loc.label}</option>
                              }) : null}


                            </Select>
                          </FormControl>
                        </div>
                        <div style={{ marginTop: 25 }}>
                          <FormControl style={{ width: '100%' }}>
                            <TextField
                              name="deal_name"
                              id="deal_name"
                              value={this.state.title}
                              onChange={(e) => { this.handleChangeP(e, 'title') }}
                              variant="outlined"
                              label="Page Title"

                            />
                          </FormControl>
                        </div>

                        <div style={{ marginTop: 25 }}>
                          <FormControl style={{ width: '100%' }}>
                            <TextField

                              multiline
                              rows="4"
                              name="deal_name"
                              id="deal_name"
                              value={this.state.description}
                              onChange={(e) => { this.handleChangeP(e, 'description') }}
                              variant="outlined"
                              label="Page text"
                            />

                          </FormControl>
                          {/* <ReactRichEditor height={200} />  */}
                        </div>
                        <div style={{ marginTop: 25 }}>
                          <FormControl style={{ width: '100%' }}>
                            <TextField
                              name="deal_name"
                              id="deal_name"
                              value={this.state.seo_title}
                              onChange={(e) => { this.handleChangeP(e, 'seo_title') }}
                              variant="outlined"
                              label="SEO Title"

                            />
                          </FormControl>
                        </div>
                        <div style={{ marginTop: 25 }}>
                          <FormControl style={{ width: '100%' }}>
                            <TextField
                              name="deal_name"
                              id="deal_name"
                              value={this.state.seo_description}
                              onChange={(e) => { this.handleChangeP(e, 'seo_description') }}
                              variant="outlined"
                              label="SEO Description"

                            />
                          </FormControl>
                        </div>
                        <div style={{ marginTop: 25 }}>
                          <FormControl style={{ width: '100%' }}>
                            <TextField
                              name="deal_name"
                              id="deal_name"
                              value={this.state.seo_url}
                              onChange={(e) => { this.handleChangeP(e, 'seo_url') }}
                              variant="outlined"
                              label="SEO URL"

                            />
                          </FormControl>
                        </div>
                        <div style={{ marginTop: 25 }}>
                          <h4>TAGS</h4>
                          <Tagselect onChange={(newValue) => this.TagChange(newValue)} id={this.state.id} options={this.props.tags} tags={this.state.tags} />


                        </div>
                        <div style={{ marginTop: 25 }}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="file-work-logo"
                            type="file"
                            onChange={this.uploadImage}
                          />
                          <label htmlFor="file-work-logo">
                            <Button component="span" variant="contained" color="default" className={classes.button}>
                              Select Image &nbsp;
                              <CloudUploadIcon className={classes.rightIcon} />
                            </Button>
                          </label>
                        </div>
                      </td>
                      <td width="50%" style={{ padding: 15, verticalAlign: 'top' }} >
                        {this.state.image_name !== "" ? (<img src={this.state.image_name} width="100%" height="auto" />) : null}

                        <a href={this.state.full_seo_url} target="_blank">{this.state.full_seo_url}</a>
                      </td>
                    </tr>

                    <tr>
                      {/* <div className="App">
                <h2>Using CKEditor 5 build in React</h2>
                <CKEditor
                    editor={ ClassicEditor }
                    data="<p>Hello from CKEditor 5!</p>"
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div> */}


                    </tr>


                  </table>

                  <Fragment><br />
                    <h4>Attached Activities</h4>
                    <div className="tab-pan">
                      <div className="tab-bok">
                        <table style={{ width: '100%' }} >
                          <thead style={{ fontSize: 12 }}>
                            <tr>
                              <th style={{ width: "10%" }} className={classes.textAlignCenter}  >#</th>
                              <th style={{ width: "25%" }}>Tour Name</th>
                              <th style={{ width: "10%" }}>Supplier</th>
                              <th style={{ width: "13%" }} className={classes.textAlignCenter}  >Tour Type</th>
                              <th style={{ width: "14%" }} className={classes.textAlignCenter}>Duration</th>
                              <th style={{ width: "17%" }} className={classes.textAlignCenter}>Category</th>
                              <th style={{ width: "7%" }} className={classes.textAlignCenter}  >Status</th>
                              <th style={{ width: "2%", backgroundColor: "primary" }}></th>
                              <th style={{ width: "2%" }}></th>
                            </tr>

                          </thead>
                          <tbody style={{ fontSize: 12 }}>

                            {this.state.activities !== null ? this.state.activities.map((act, index) => (
                              <tr key={act.id}>
                                <td className={classes.textAlignCenter} >{index + 1}</td>
                                <td >{act.title} <br /> <small>TourID-{act.tour_id}</small> </td>
                                <td >{act.supplier.supplier_profile.work_name}<br /> <small> ID : {act.supplier.sid} </small> </td>
                                <td className={classes.textAlignCenter} >{this.getTripType(act.tour_type)}</td>
                                <td className={classes.textAlignCenter} >{act.duration_value} &nbsp;{this.getDuration(act.duration)}</td>
                                <td>{act.category[0] !== undefined ? act.category[0].category.name : null} {act.category.length > 1 ? (<em>+{act.category.length - 1} more</em>) : null} </td>
                                <td className={classes.textAlignCenter}  >{StatusChip(act.status)}</td>
                                <td className={classes.textAlignCenter}  >

                                </td>
                                <td className={classes.textAlignCenter}  >
                                  <IconButton onClick={() => this.removeFromPage(index)} color="white" >
                                    <DeleteIcon className={classes.rightIcon} />
                                  </IconButton>


                                </td>
                              </tr>
                            )) : null}
                          </tbody>
                        </table>
                      </div></div></Fragment>




                  <TableRow>

                    <br />

                    <td>
                      <div style={{ float: 'right', align: 'right' }}   >
                        &nbsp;&nbsp;&nbsp; <Button onClick={this.onSave} style={{ background: "#f7a414", marginLeft: '445px' }} size="small" variant="contained" color="primary" className={classes.button}  >Save</Button>
                      </div>
                    </td>
                    {/* <Button onClick={this.onSave} style={{background:"#f7a414", marginLeft: 0 }} variant="contained" color="primary" className={classes.button}  >Save</Button> */}

                  </TableRow>

                  <br /> <br />

                </Paper>
                {this.state.tags === null || (this.state.tags !== null && this.state.tags.length === 0) ? (
                  <Fragment><br />
                    <h4>Search Activities</h4>
                    <Paper className={classes.root} elevation={1} style={{ marginBottom: 30 }}>

                      <div className="tab-pan">
                        <div className="tab-bok">
                          <div float='right'>
                            <Table align="right" style={{ width: "30%", textAlign: "right", alignContent: 'right', alignSelf: 'center', float: 'right' }} className={classes.table}  >

                              <tr align="right">
                                <td align="right" style={{ width: "30%" }}>

                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      label='Product Name/ ID'
                                      value={this.state.keyword}
                                      onChange={this.selectkeyword}
                                      style={{ width: '180px' }}
                                    >

                                    </TextField>
                                  </FormControl>

                                </td>

                                <td align="right" style={{ width: "20%" }} >

                                  <FormControl className={classes.formControl}>
                                    <InputLabel shrink htmlFor="status-native-simple">Tour type</InputLabel>
                                    <Select
                                      native
                                      // value={this.state.tour_type}

                                      // onChange={this.handleChange('tour_type')}
                                      // onChange={this.handleChange('tour_type')}
                                      onChange={this.selecttourType}


                                      value={this.state.tourtype}
                                      inputProps={{
                                        name: 'tour_type',
                                        id: 'status-native-simple',
                                      }}
                                    >
                                      <option value="">  All  </option>
                                      <option value="1">  Round Tour  </option>
                                      <option value="2">  Day Tour    </option>

                                    </Select>
                                  </FormControl>

                                </td>

                                <td alighn="right" style={{ width: "20%" }} >

                                  <FormControl className={classes.formControl}>
                                    <InputLabel shrink htmlFor="status-native-simple"> Status </InputLabel>
                                    <Select
                                      native
                                      // value={this.state.status}

                                      // onChange={this.handleChange('status')}
                                      onChange={this.selecttourStatus}

                                      value={this.state.tourstatus}
                                      inputProps={{
                                        name: 'status',
                                        id: 'status-native-simple',
                                      }}
                                    >

                                      <option value="" >All</option>
                                      <option value="0">Draft</option>
                                      <option value="1">Approved</option>
                                      <option value="2">Rejected</option>
                                      <option value="3">In Review</option>
                                      <option value="4">Banned</option>
                                      <option value="5">Trashed</option>



                                    </Select>
                                  </FormControl>

                                </td>

                                <td alighn="right" style={{ width: "20%" }} >
                                  <FormControl style={{ width: 240 }}>
                                    <SearchSelect
                                      options={this.props.suppliers}
                                      getOptionLabel={option => `${option.work_name}`}
                                      getOptionValue={option => `${option.id}`}
                                      value={this.state.supplier}
                                      placeholder="Supplier"
                                      onSelect={this.selectSupplier}
                                      onChange={this.handleChange('supplier')}
                                    />
                                  </FormControl>
                                </td>
                              </tr>
                            </Table>

                          </div>

                          <table style={{ width: '100%' }} >
                            <thead style={{ fontSize: 12 }}>
                              <tr>
                                <th style={{ width: "10%" }} className={classes.textAlignCenter}  >#</th>
                                <th style={{ width: "25%" }}>Tour Name</th>
                                <th style={{ width: "10%" }}>Supplier</th>
                                <th style={{ width: "13%" }} className={classes.textAlignCenter}  >Tour Type</th>
                                <th style={{ width: "14%" }} className={classes.textAlignCenter}>Duration</th>
                                <th style={{ width: "17%" }} className={classes.textAlignCenter}>Category</th>
                                <th style={{ width: "7%" }} className={classes.textAlignCenter}  >Status</th>
                                <th style={{ width: "2%", backgroundColor: "primary" }}></th>
                                <th style={{ width: "2%" }}></th>
                              </tr>

                            </thead>
                            <tbody style={{ fontSize: 12 }}>

                              {this.props.ActivitiesList !== null ? this.props.ActivitiesList.data.map((act, index) => (
                                <tr key={act.id}>
                                  <td className={classes.textAlignCenter} >{parseInt(this.props.ActivitiesList.from) + index}</td>
                                  <td >{act.title} <br /> <small>TourID-{act.tour_id}</small> </td>
                                  <td >{act.supplier.supplier_profile.work_name}<br /> <small> ID : {act.supplier.sid} </small> </td>
                                  <td className={classes.textAlignCenter} >{this.getTripType(act.tour_type)}</td>
                                  <td className={classes.textAlignCenter} >{act.duration_value} &nbsp;{this.getDuration(act.duration)}</td>
                                  {/* <td >  {act.catagories}</td> */}
                                  <td>{act.category[0] !== undefined ? act.category[0].category.name : null} {act.category.length > 1 ? (<em>+{act.category.length - 1} more</em>) : null} </td>
                                  <td className={classes.textAlignCenter}  >{StatusChip(act.status)}</td>
                                  <td className={classes.textAlignCenter}  >

                                  </td>
                                  <td className={classes.textAlignCenter}  >
                                    <IconButton onClick={() => this.addTourToPage(act)} color="white" >
                                      <AddIcon className={classes.rightIcon} />
                                    </IconButton>


                                  </td>
                                </tr>
                              )) : null}
                            </tbody>
                          </table>

                          {this.props.ActivitiesList !== null ?
                            <TablePagination
                              component="div"
                              count={this.props.ActivitiesList.total}
                              rowsPerPage={this.props.ActivitiesList.per_page}
                              page={this.props.ActivitiesList.current_page - 1}
                              backIconButtonProps={{
                                'aria-label': 'previous page',
                              }}
                              nextIconButtonProps={{
                                'aria-label': 'next page',
                              }}
                              onChangePage={this.handleChangePage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            /> : null}

                        </div>
                      </div>


                    </Paper></Fragment>) : null}

              </TableCell>
            </TableRow>
          </TableBody>
          <br />
        </Table>

      </div>
      </div>

    </Fragment>
  }
}

LocationPageCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(LocationPageCreate);
